import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Seo } from "../gatsby-components"
import { Layout } from "../components"
import {
  FlexibleGridBlock,
  FlexibleGridBlockItem,
  FlexibleGridBlockStack,
  FlexibleGridBlockImage,
} from "../blocks"
import { layout } from "../temp-content/layout"

const BaustellePage = () => {
  return (
    <>
      <Seo title="Baustelle" />
      <Layout {...layout}>
        <div className="grid gap-8 sm:gap-12">
          {/* First Row */}
          <FlexibleGridBlock
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/firstleft.jpg"
                      alt="firstleft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockStack
                colSpan={1}
                items={[
                  <StaticImage
                    src="../images/baustelle-temp/firstRightTop.jpg"
                    alt="firstRightTop"
                  />,
                  <StaticImage
                    src="../images/baustelle-temp/firstRightBottom.jpg"
                    alt="firstRightBottom"
                  />,
                ]}
              />,
            ]}
            noPaddingTop
            noPaddingBottom
          />
          {/* Second Row */}
          <FlexibleGridBlock
            noPaddingTop
            noPaddingBottom
            items={[
              <FlexibleGridBlockItem>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/second.jpg"
                      alt="second"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Third Row */}
          <FlexibleGridBlock
            noPaddingTop
            noPaddingBottom
            cols={3}
            items={[
              <FlexibleGridBlockItem>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/thirdLeft.jpg"
                      alt="thirdLeft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/thirdRight.jpg"
                      alt="thirdRight"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* fourth Row */}
          <FlexibleGridBlock
            noPaddingTop
            noPaddingBottom
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={2}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/fourthLeft.jpg"
                      alt="fourthLeft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/fourthRight.jpg"
                      alt="fourthRight"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Fifth Row */}
          <FlexibleGridBlock
            noPaddingTop
            noPaddingBottom
            cols={3}
            items={[
              <FlexibleGridBlockItem colSpan={1}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/fifthLeft.jpg"
                      alt="fifthLeft"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
              <FlexibleGridBlockItem colSpan={1}>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/fifthMiddle.jpg"
                      alt="fifthMiddle"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
          {/* Sixth Row */}
          <FlexibleGridBlock
            noPaddingTop
            items={[
              <FlexibleGridBlockItem>
                <FlexibleGridBlockImage
                  image={
                    <StaticImage
                      src="../images/baustelle-temp/sixth.jpg"
                      alt="sixth"
                    />
                  }
                />
              </FlexibleGridBlockItem>,
            ]}
          />
        </div>
      </Layout>
    </>
  )
}

export default BaustellePage
